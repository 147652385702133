var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("QrcodeStream", {
        staticClass: "h-100",
        attrs: { track: _vm.paintOutline },
        on: { decode: _vm.onDecode, init: _vm.onInit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }