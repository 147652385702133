<template>
  <div>
    <QrcodeStream
      class="h-100"
      :track="paintOutline"
      @decode="onDecode"
      @init="onInit"
    />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapActions } from 'vuex'
import { resStatus } from '../../../utils/resUtils'

export default {
  components: {
    QrcodeStream,
  },

  data: () => ({
    result: '',
    error: '',
  }),

  methods: {
    ...mapActions(['qrcode_login', 'show_alert']),

    onDecodeSuccess(data) {
      this.show_alert({
        icon: 'done',
      })
      this.$router.replace({ path: '/user' })
    },
    onDecodeFail() {
      this.show_alert({
        icon: 'fail',
      })
      this.$router.replace({ path: '/user' })
    },

    async onDecode(result) {
      try {
        const token = { lgToken: result }
        const res = await this.qrcode_login(token)
        resStatus(res, this.onDecodeSuccess, this.onDecodeFail)
      } catch {
        this.onDecodeFail()
      }
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permission'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>
